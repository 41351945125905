import { cpf, cnpj } from 'cpf-cnpj-validator';
import moment from 'moment';
import brands from './brands';

export default {
  isTaxpayerId: (taxpayerId) => {
    if (taxpayerId != null) {
      let strCpf  = taxpayerId.replace(/\.|-/g,"");

      return cpf.isValid(strCpf);
    }
  },

  isTaxpayerIdOrEin: (value) => {
    if (value != null) {
      let number = value.replace(/\.|-/g,"");

      return number.length == 15 ? cnpj.isValid(number) : cpf.isValid(number);
    }
  },

  isDate: (date) => {
    if (date != null && date.length == 5) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);

      let validateMonth = date.toString().substr(0, 2);
      let validateYear = date.toString().substr(-2);

      if ((validateMonth >= currentMonth && validateYear >= currentYear)
        || (validateYear > currentYear)) {
        return moment(date, "MM-YY").isValid();
      }
    } else {
      return false;
    }
  },

  isDateOfBirth: (date) => {
    if (date != null) {
      return moment(date, "DD-MM-YYYY").isValid();
    }
  },

  isEmail: (email) => {
    if (email != null) {
      let regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      return !!email.match(regEmail);
    } else {
      return false;
    }
  },

  isPostalCode: (postalCode) => {
    if (postalCode != null) {
      let valueChar = postalCode.replace(/[^0-9]/g, '');

      return valueChar.length === 8;
    }
  },

  isFullName: (name) => {
    if (name != null) {
      if (name.indexOf(' ') >= 0) {
        let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;

        return name.match(regName);
      }
    } else {
      return false;
    }
  },

  isValidFullName: (name) => {
    if (!name) {
      return false;
    }

    if (name.indexOf(' ') >= 0) {
      let regName = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;
      return regName.test(name);
    }

    return false;
  },

  /* Caution: Changing a regex, you must change it everywhere */
  isValidCard: (number) => {
    if (number != null) {
      let regCards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        master: /^5[1-5]|^2(2(2[1-9]|[3-9])|[3-6]|7([01]|20))/,
        elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
        hiper: '^606282|^637095|^637599|^637568',
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        banese: /^636117/,
        amex: /^3[47][0-9]{13}/,
        aura: /^((?!504175))^((?!5067))(^50[0-9])/
      };

      for (var card in regCards) if (number.match(regCards[card])) return card;
      return false;
    }
  },

  isValidCreditCard: (number) => {
    if (number != null) {
      return false;
    }

    for (let card of brands) {
      return card.bins.test(number);
    }

    return false;
  },

  isValidPhoneNumber: (phone) => {
    if (phone != null) {
      let formatedPhone = phone.replace(/[^0-9]/g,'');
      let lengthPhone = formatedPhone.length - 4;

      return lengthPhone === 8 || lengthPhone === 9;
    } else {
      return false;
    }
  }
};