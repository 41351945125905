<template>
  <div class="payment-method-card">
    <default-section title="Meio de pagamento - cartão de crédito">
      <template v-slot:content>
        <div class="content">
          <ValidationObserver tag="form" ref="form" @input="validateForm()">
            <div class="tree-fields">
              <input-field
                label="Número do cartão"
                placeholder="Ex. 5381579886310193"
                rules="required|card"
                styles="large"
                type="text"
                class="field"
                :hasFlag="true"
                :disable="isBlocked"
                v-model="data.cardNumber"
              />

              <div class="two-fields">
                <input-field
                  label="Data de validade"
                  placeholder="Ex. 05/20"
                  mask="##/##"
                  rules="required|validExpirationCard"
                  styles="small"
                  class="field"
                  type="text"
                  :disable="isBlocked"
                  v-model="data.dateValidate"
                />

                <input-field
                  label="Cód. de segurança"
                  placeholder="Ex. 452"
                  rules="required"
                  styles="small"
                  class="field"
                  :maxLength="4"
                  type="text"
                  :disable="isBlocked"
                  v-model="data.securityCode"
                />
              </div>
            </div>

            <div class="tree-fields">
              <input-field
                label="Nome completo do titular do cartão"
                placeholder="Ex. Maria Ferreira Dias"
                rules="required|completedName"
                styles="large"
                type="text"
                class="field"
                :disable="isBlocked"
                v-model="data.fullName"
              />

              <div class="two-fields">
                <input-field
                  label="CPF/CNPJ"
                  placeholder="CPF ou CNPJ"
                  rules="required|taxpayerIdOrEin"
                  styles="small"
                  class="field"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  type="text"
                  :disable="isBlocked"
                  v-model="data.taxpayerIdOrEin"
                />

                <input-field
                  label="Data de nascimento"
                  placeholder="Ex. 20/05/1987"
                  rules="required|date"
                  styles="small"
                  class="field"
                  mask="##/##/####"
                  type="text"
                  :disable="isBlocked"
                  v-model="data.dateOfBirth"
                />
              </div>
            </div>

            <input-field
              label="Telefone"
              placeholder="Ex. (48) 9 9999-9999"
              rules="required"
              styles="medium"
              mask="phone"
              type="text"
              class="field"
              :disable="isBlocked"
              v-model="data.phone"
            />
          </ValidationObserver>
        </div>
      </template>
    </default-section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import DefaultSection from '@/components/base/DefaultSection';
  import InputField from '@/components/InputField';
  import Validation from '@/services/validations';
  import { ValidationObserver } from 'vee-validate';

  export default {
    name: 'paymentMethodCard',

    props: {
      isBlocked: {
        type: Boolean,
        required: true,
      }
    },

    components: {
      ValidationObserver,
      'default-section': DefaultSection,
      'input-field': InputField
    },

    computed: {
      ...mapState({
        data: state => state.data,
        formStepStatus: state => state.formStepStatus
      })
    },

    watch: {
      'formStepStatus.disablePaymentMethodForm'(value) {
        if (value) {
          this.$refs.form.reset();
        }
      }
    },

    methods: {
      validateForm() {
        if (this.passedForm()
          && Validation.isTaxpayerIdOrEin(this.data.taxpayerIdOrEin) && Validation.isDate(this.data.dateValidate) && Validation.isDateOfBirth(this.data.dateOfBirth)
          && Validation.isFullName(this.data.fullName) && Validation.isValidCard(this.data.cardNumber) && Validation.isValidPhoneNumber(this.data.phone)
        ) {
          this.formStepStatus.fourthStep = 'check';
          this.formStepStatus.disableButton = false;
        } else {
          this.formStepStatus.fourthStep = 'wait';
          this.formStepStatus.disableButton = true;
        }
      },

      passedForm() {
        return !(!this.data.phone || !this.data.dateOfBirth || !this.data.cardNumber || !this.data.dateValidate
          || !this.data.securityCode || !this.data.fullName || !this.data.taxpayerIdOrEin);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .payment-method-card {
    .content {
      margin-top: 1.5rem;

      .field {
        margin-right: 1rem;
      }

      .tree-fields {
        display: flex;
        margin-bottom: 1rem;

        .two-fields {
          display: flex;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .payment-method-card {
      .content {
        .tree-fields {
          flex-direction: column;

          .two-fields {
            margin-top: 1rem;

            .field:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .payment-method-card {
      .content {
        .tree-fields {
          .two-fields {
            flex-direction: column;

            .field:last-child {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
</style>